import React, { useEffect, useState } from "react";
import { theme } from "../../theme";
import InputField from "../common/InputField";
import SelectField from "../common/SelectField";
import { day, gradeData, HebrewMonths } from "../../data";
import CButton from "../common/CButton";
import SubmittedContemt from "./SubmittedContemt";
import { db, storage } from "../../db/firebase";
import { addDoc, collection, Timestamp, updateDoc } from "firebase/firestore";
import toast from "react-hot-toast";
import ImageField from "../common/ImageField";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

const EnvelopeContent = ({ schools }) => {
  const [img, setImg] = useState(null);
  const [envelope, setEnvelope] = useState({
    firstName: "",
    lastName: "",
    grade: "",
    rebbe: "",
    Yeshiava: "",
    birthMonth: "",
    birthDay: "",
    parentEmail: "",
    studentImgUrl: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () => {
    const {
      firstName,
      lastName,
      grade,
      rebbe,
      Yeshiava,
      birthDay,
      birthMonth,
      parentEmail,
      studentImgUrl,
    } = envelope;
    if (
      firstName &&
      lastName &&
      grade &&
      rebbe &&
      Yeshiava &&
      birthDay &&
      birthMonth &&
      parentEmail &&
      studentImgUrl
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setIsButtonDisabled(!validateForm());
  }, [envelope]);

  const onInputChange = (e) => {
    setEnvelope({
      ...envelope,
      [e.target.id]: e.target.value,
    });
  };

  const onSelectChange = (id, value) => {
    setEnvelope({
      ...envelope,
      [id]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    setIsLoading(true);
    try {
      let imgUrl = "";
      if (img) {
        const storageRef = ref(storage, `envelopes/${uuidv4()}_${img.name}`);
        const snapshot = await uploadBytes(storageRef, img);
        imgUrl = await getDownloadURL(snapshot.ref);
      }
      const payload = {
        ...envelope,
        studentImgUrl: imgUrl,
        createdAt: Timestamp.now(),
      };
      const envelopeDocRef = await addDoc(collection(db, "envelopes"), payload);

      const envelopeId = envelopeDocRef.id;
      await updateDoc(envelopeDocRef, { envelopeId, isActive: true });
      setIsSubmitted(true);
    } catch (error) {
      toast.error("Failed to submit envelope request");
    } finally {
      setIsButtonDisabled(false);
      setIsLoading(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setEnvelope({
        ...envelope,
        studentImgUrl: URL.createObjectURL(file),
      });
      setImg(file);
    } else {
      setEnvelope({
        ...envelope,
        studentImgUrl: "",
      });
      setImg(null);
    }
  };

  return (
    <>
      {isSubmitted ? (
        <SubmittedContemt />
      ) : (
        <div className="overflow-y-auto custom-scrollbar h-[80vh] md:h-full md:max-h-[90vh]">
          <form
            className="flex flex-col justify-center items-center w-full max-w-[90%] mx-auto "
            onSubmit={handleSubmit}
          >
            <div
              className="mb-2 sm:mb-3 md:mb-4 text-center"
              style={theme.typography.title}
            >
              Envelope Request
            </div>
            <div
              style={theme.typography.subheading2}
              className="text-center mb-4 sm:mb-6"
            >
              For your son to earn points in the minyan he will need to have an
              envelope, fill out this form to request one
            </div>

            <div className="flex flex-col space-y-4 sm:space-y-6 md:space-y-8 items-center w-full">
              <div className="flex flex-col md:flex-row md:space-x-4 w-full p-4 sm:p-6">
                <div className="flex flex-col space-y-4 w-full md:w-1/2">
                  <InputField
                    label="First Name"
                    placeholder="Type here"
                    id="firstName"
                    value={envelope.firstName}
                    onChange={onInputChange}
                  />

                  <InputField
                    label="Last Name"
                    placeholder="Type here"
                    id="lastName"
                    value={envelope.lastName}
                    onChange={onInputChange}
                  />

                  <SelectField
                    label="Yeshiava"
                    items={schools}
                    onChange={onSelectChange}
                    id="Yeshiava"
                    value={envelope.Yeshiava}
                  />
                </div>

                <div className="flex flex-col space-y-4 w-full md:w-1/2 mt-4 md:mt-0">
                  <div className="flex flex-col sm:flex-row sm:gap-4">
                    <div className="w-full sm:w-1/3 mb-4 sm:mb-0">
                      <SelectField
                        label="Grade"
                        items={gradeData}
                        onChange={onSelectChange}
                        id="grade"
                        value={envelope.grade || "Age"}
                      />
                    </div>
                    <div className="w-full sm:w-2/3">
                      <InputField
                        label="Rebbe"
                        placeholder="Type here"
                        id="rebbe"
                        value={envelope.rebbe}
                        onChange={onInputChange}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col sm:flex-row sm:gap-4">
                    <div className="w-full sm:w-1/2 mb-4 sm:mb-0">
                      <SelectField
                        label="Birthday Month"
                        items={HebrewMonths}
                        onChange={onSelectChange}
                        id="birthMonth"
                        value={envelope.birthMonth || "Month"}
                      />
                    </div>
                    <div className="w-full sm:w-1/2">
                      <SelectField
                        label="Birthday Day"
                        items={day}
                        onChange={onSelectChange}
                        id="birthDay"
                        value={envelope.birthDay || "Day"}
                      />
                    </div>
                  </div>

                  <InputField
                    label="Parent Email"
                    placeholder="Type here"
                    id="parentEmail"
                    value={envelope.parentEmail}
                    onChange={onInputChange}
                  />
                </div>
              </div>

              <ImageField
                onChange={handleImageChange}
                img={img}
                imgUrl={envelope.studentImgUrl}
              />

              <CButton
                name= {"Submit"}
                disabled={isButtonDisabled}
                loading={isLoading}
              />
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default EnvelopeContent;
