import React from 'react';

const FramedImage = ({frame, image, width, height}) => {
  return (
    <div className="frame-container" style={{width: width, height: height}}>
      <img src={frame} alt="Frame" className="frame" />
      <img src={image} alt="Content" className="img_content" />
    </div>
  );
};

export default FramedImage;
