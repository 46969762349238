import React, { useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";

const Completion = () => {
  const [count, setCount] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prev) => prev - 1);
    }, 1000);

    count === 0 && window.location.replace("/");

    return () => clearInterval(interval);
  }, [count]);

  return (
    <div className="flex flex-col justify-center items-center w-full h-screen bg-gray-100 p-4">
      <div className="bg-white p-6 sm:p-8 rounded-lg shadow-lg text-center max-w-md w-full">
        <FaCheckCircle className="text-green-500 text-5xl sm:text-6xl mb-4" />
        <h1 className="text-2xl sm:text-3xl font-bold mb-2">Thank You!</h1>
        <h2 className="text-lg sm:text-xl mb-4">
          Your donation has been successfully processed.
        </h2>
        <p className="text-base sm:text-lg mb-4 text-gray-400">
          You will be redirected to the homepage in {count} seconds.
        </p>
        <div
          id="messages"
          className="bg-green-100 text-green-800 p-4 rounded-lg border border-green-200"
        >
          <h3 className="text-lg sm:text-xl font-semibold">
            Thank you for your generosity! 🎉
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Completion;
