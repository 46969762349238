import React, { useEffect, useState } from "react";
import SelectableButton from "../common/SelectableButton";
import { theme } from "../../theme";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import CheckoutForm from "../CheckoutForm";
import DollarInputField from "../common/DollarInputField";

const DonateContent2 = ({ onClose }) => {
  const [selectedAmount, setSelectedAmount] = useState("770");
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [formKey, setFormKey] = useState(0);
  const [customAmount, setCustomAmount] = useState("");

  const handleButtonClick = (amount) => {
    setSelectedAmount(amount);
  };

  const handleCustomAmountChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setCustomAmount(value);
  };

  useEffect(() => {
    const fetchPublishKey = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/payment/config`
        );
        const publishableKey = res.data.data.publishableKey;
        setStripePromise(loadStripe(publishableKey));
      } catch (error) {
        console.error(error);
      }
    };

    fetchPublishKey();
  }, []);

  useEffect(() => {
    const fetchClientSecret = async () => {
      let amount = selectedAmount === "Other" ? customAmount : selectedAmount;

      if (amount === "" || parseInt(amount, 10) < 1) {
        return;
      }

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/payment/create-payment-intent`,
          {
            amount: parseInt(amount, 10) * 100,
          }
        );
        setClientSecret(res.data.data.clientSecret);

        setFormKey((prev) => prev + 1);
      } catch (error) {
        console.error(error);
      }
    };

    fetchClientSecret();
  }, [selectedAmount, customAmount]);

  const appearance = {
    theme: "flat",
    variables: {
      fontFamily: '"Gill Sans", sans-serif',
      fontLineHeight: "1.5",
      borderRadius: "10px",
      colorBackground: "#F6F8FA",
      accessibleColorOnColorPrimary: "#5BC9FE",
      colorPrimary: "#5BC9FE",
    },
    rules: {
      ".Block": {
        backgroundColor: "var(--colorBackground)",
        boxShadow: "none",
        padding: "12px",
      },
      ".Input": {
        padding: "12px",
        borderRadius: "10px",
      },
      ".Input:focus": {
        borderColor: "#5BC9FE",
        boxShadow: "0 0 0 2px #5BC9FE",
      },
      ".Input:disabled, .Input--invalid:disabled": {
        color: "lightgray",
      },
      ".Tab": {
        padding: "10px 12px 8px 12px",
        border: "none",
      },
      ".Tab:hover": {
        border: "none",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
      },
      ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
        border: "none",
        backgroundColor: "#fff",
        boxShadow:
          "0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
      },
      ".Label": {
        fontWeight: "500",
      },
    },
  };

  return (
    <div className="overflow-y-auto custom-scrollbar h-[80vh] md:h-full md:max-h-[80vh]">
      <div className="flex flex-col justify-center items-center w-full px-4 sm:px-6 md:px-8 ">
        <div
          className="mb-2 sm:mb-3 md:mb-4 text-center"
          style={theme.typography.title}
        >
          Donate
        </div>

        <div
          className="text-center mb-4 sm:mb-6"
          style={theme.typography.subheading2}
        >
          Help keep the minyan going!
        </div>

        <div className="flex flex-wrap justify-center gap-2 sm:gap-3 md:gap-4 w-full mb-6">
          {["54", "108", "250", "360", "770", "Other"].map((amount) => (
            <div key={amount} className="sm:w-auto">
              {amount === "Other" && selectedAmount === "Other" ? (
                <DollarInputField
                  value={customAmount}
                  onChange={handleCustomAmountChange}
                />
              ) : (
                <SelectableButton
                  isSelected={selectedAmount === amount}
                  onClick={() => handleButtonClick(amount)}
                >
                  {amount === "Other" ? amount : `$${amount}`}
                </SelectableButton>
              )}
            </div>
          ))}
        </div>

        {clientSecret && stripePromise ? (
          <div className="w-[90%] h-[200px] sm:w-full sm:h-full justify-center items-center">
            <Elements
              key={formKey}
              stripe={stripePromise}
              options={{ clientSecret, appearance }}
            >
              <CheckoutForm
                clientSecret={clientSecret}
                onClose={onClose}
                selectedAmount={
                  selectedAmount === "Other" ? customAmount : selectedAmount
                }
              />
            </Elements>
          </div>
        ) : (
          <div className="w-[250px] h-[200px] sm:w-[400px] sm:h-[400px] justify-center items-center">
            <div className="w-full h-full flex justify-center items-center">
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DonateContent2;
