import React, { useState, useEffect } from "react";
import { theme } from "../../theme";
import AnimatedChestbox from "../common/AnimatedChestBox";


function ThisWeekPrices({weeksData, currentWeek}) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedWeek, setSelectedWeek] = useState(currentWeek);

  return (
    <div className="flex flex-col items-center w-full px-4 sm:px-6 md:px-8">
      <div
        className="text-center mb-2 sm:mb-3 md:mb-4"
        style={theme.typography.title}
      >
        Parshes {selectedWeek} Prizes
      </div>
      <div
        className="text-center mb-3 sm:mb-4 md:mb-6"
        style={theme.typography.subheading2}
      >
        Scroll to View Previous weeks winners
      </div>
      <div className="flex items-center justify-center w-full relative ">
        <AnimatedChestbox
          weeksData={weeksData}
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
          selectedWeek={selectedWeek}
          setSelectedWeek={setSelectedWeek}
        />
      </div>
    </div>
  );
}

export default ThisWeekPrices;
