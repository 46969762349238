import React from "react";
import Lottie from "react-lottie";
import successData from "../../assets/lotties/success.json";
import { theme } from "../../theme";

const SubmittedContemt = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: successData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="mb-[7px]" style={theme.typography.title}>
        Envelope Request Done!
      </div>
      <div style={theme.typography.subheading2} className="mb-[30px]">
        You'll get notified by email once you envelope has been prepared
      </div>

      <Lottie options={defaultOptions} height={200} width={200} />
    </div>
  );
};

export default SubmittedContemt;
