import React, { useEffect, useState } from "react";
import SelectField from "../common/SelectField";
import InputField from "../common/InputField";
import CButton from "../common/CButton";
import { theme } from "../../theme";
import { weeksDropdownData, requestAliyaType } from "../../data";
import RadioField from "../common/RadioField";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { db } from "../../db/firebase";
import toast from "react-hot-toast";

const RequestAliyaContent = ({ onClose }) => {
  const [aliya, setAliya] = useState({
    name: "",
    fatherName: "",
    nickName: "",
    parshas: "",
    inHonorOf: "",
    type: "Cohen",
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = () => {
    const { name, fatherName, nickName, parshas, inHonorOf } = aliya;
    if (name && fatherName && nickName && parshas && inHonorOf) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setIsButtonDisabled(!validateForm());
  }, [aliya]);

  const onInputChange = (e) => {
    setAliya({
      ...aliya,
      [e.target.id]: e.target.value,
    });
  };

  const onSelectChange = (id, value) => {
    setAliya({
      ...aliya,
      [id]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsButtonDisabled(true);
    try {
      const payload = { ...aliya, createdAt: Timestamp.now() };
      await addDoc(collection(db, "requests"), payload);
      toast.success("Aliya request submitted successfully");
    } catch (error) {
      toast.error("Failed to submit Aliya request");
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  const onRadioChange = (e) => {
    setAliya({
      ...aliya,
      type: e.target.value,
    });
  };

  return (
    <div className="overflow-y-auto custom-scrollbar h-[80vh] md:h-full">
      <form
        className="flex flex-col justify-center items-center w-full max-w-[900px] mx-auto px-4"
        onSubmit={handleSubmit}
      >
        <div
          className="mb-2 md:mb-[7px] text-center"
          style={theme.typography.title}
        >
          Request Aliya
        </div>
        <div
          className="text-center mb-4 md:mb-6"
          style={theme.typography.subheading2}
        >
          Fill out this form to request an aliya
        </div>

        <div className="flex flex-col space-y-6 md:space-y-[42px] items-center w-full">
          <div className="flex flex-col md:flex-row md:space-x-[4%] w-full">
            <div className="flex flex-col space-y-4 md:space-y-[17px] w-full md:w-[48%] mb-6 md:mb-0">
              <InputField
                label="שם"
                placeholder="Type here"
                id="name"
                value={aliya.name}
                onChange={onInputChange}
              />

              <InputField
                label="בן"
                placeholder="Type here"
                id="fatherName"
                value={aliya.fatherName}
                onChange={onInputChange}
              />

              <InputField
                label="שם המשפחה"
                placeholder="Type here"
                id="nickName"
                value={aliya.nickName}
                onChange={onInputChange}
              />
            </div>

            <div className="flex flex-col space-y-4 md:space-y-[17px] w-full md:w-[48%]">
              <SelectField
                label="Parsha's"
                items={weeksDropdownData}
                onChange={onSelectChange}
                id="parshas"
                value={aliya.parshas}
              />

              <InputField
                label="In Honor of"
                placeholder="Type here"
                id="inHonorOf"
                value={aliya.inHonorOf}
                onChange={onInputChange}
              />

              <div className="flex justify-center items-center h-[100%] pt-4 md:pt-7">
                <RadioField
                  options={requestAliyaType}
                  onChange={onRadioChange}
                  checked={aliya.type}
                />
              </div>
            </div>
          </div>

          <CButton
            name="Submit"
            className="w-full sm:w-auto"
            disabled={isButtonDisabled}
            loading={isLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default RequestAliyaContent;
