import React from "react";
import { theme } from "../../theme";
import { FaInfoCircle, FaRegCreditCard } from "react-icons/fa";

const PaymentInputField = ({
  autoFocus,
  label,
  placeholder,
  id,
  value,
  onChange,
  trailing,
}) => {
  const filledStyle = {
    backgroundColor: "#F6F8FA",
    // border: "1px solid #E2E6EE",
    // boxShadow: "0px 4px 18px 0px #9EA8BD4F",
    height: "42.39px",
    padding: "8px 12px",
    gap: "10px",
    borderRadius: "10px",
    opacity: 1,
    fontFamily: "Roboto",
  };

  const emptyStyle = {
    backgroundColor: "#F6F8FA",
    height: "42.39px",
    padding: "8px 12px",
    gap: "10px",
    borderRadius: "10px",
    opacity: 1,
    fontFamily: "Roboto",
  };
  return (
    <div className="flex flex-col space-y-[4px]">
      <div style={theme.typography.paymentInputText}>{label}</div>
      <div className="relative">
        <input
          id={id}
          value={value}
          placeholder={placeholder}
          className="w-full text-[#30313d] focus:outline-none focus:border-2 focus:border-[#5BC9FE]"
          style={value ? filledStyle : emptyStyle}
          onChange={onChange}
          autoFocus={autoFocus || false}
        />
        {trailing && (
          <div
            className="absolute right-[9px] top-1/2 transform -translate-y-1/2 h-[24px] w-[24px] flex justify-center items-center"
            style={{ borderRadius: "5px" }}
          >
            {id === "creditCardNumber" ? (
              <FaRegCreditCard color="#BCC3D1" />
            ) : (
              <FaInfoCircle color="#BCC3D1" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentInputField;
