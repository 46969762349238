const primaryGradient = "linear-gradient(180deg, #C8EAFF 0%, #86D8FF 100%)";

export const theme = {
  colors: {
    primary: "#5BC9FE",
    secondary: "#FFFFFF",
    success: "#28a745",
    danger: "#dc3545",
    warning: "#ffc107",
    info: "#17a2b8",
    light: "#f8f9fa",
    dark: "#343a40",
    white: "#fff",
    black: "#000",
    backgroundPrimary: primaryGradient,
    popupBackground: "#F6FCFF",
    buttonTextUnselected: "5BC9FE",
  },
  typography: {
    heading: {
      fontFamily: "Fredoka",
      fontWeight: 500,
      color: "#597FA4",
      fontSize: "64px",
      lineHeight: "77.44px",
      Align: "center",
    },
    subheading: {
      fontFamily: "Baloo 2",
      fontWeight: 400,
      color: "#909090",
      fontSize: "36px",
      lineHeight: "44.66px",
      Align: "center",
    },
    title: {
      fontFamily: "Baloo 2",
      fontWeight: 400,
      color: "#5BC9FE",
      fontSize: "37px",
      lineHeight: "45.91px",
      Align: "center",
    },
    subheading2: {
      fontFamily: "Baloo 2",
      fontWeight: 400,
      color: "#909090",
      fontSize: "22px",
      lineHeight: "27.3px",
      Align: "center",
      textAlign: "center",
    },
    paragraph: {
      fontFamily: "Fresca",
      fontWeight: 400,
      color: "#597FA4",
      fontSize: "19px",
      lineHeight: "21.93px",
      Align: "center",
      textAlign: "center",
    },
    iconLabel: {
      fontFamily: "Roboto Condensed",
      fontWeight: 700,
      color: "#FFFFFF",
      fontSize: "16px",
      lineHeight: "18.75px",
      Align: "center",
      letterSpacing: "-1%",
    },
    inputLabel: {
      fontFamily: "Noto Sans",
      fontWeight: 600,
      color: "#666666",
      fontSize: "12px",
      lineHeight: "18px",
    },
    placeholder: {
      fontFamily: "Noto Sans",
      fontWeight: 400,
      color: "#666666",
      fontSize: "16px",
      lineHeight: "24px",
    },
    primaryButtonText: {
      fontFamily: "Noto Sans",
      fontWeight: 700,
      color: "#FFFFFF",
      fontSize: "16px",
      lineHeight: "24px",
      Align: "center",
    },
    secondaryButtonText: {
      fontFamily: "Baloo 2",
      fontWeight: 400,
      color: "#5BC9FE",
      lineHeight: "40.94px",
      Align: "center",
    },
    selectedButtonText: {
      fontFamily: "Baloo 2",
      fontWeight: 400,
      color: "#FFFFFF",
      lineHeight: "40.94px",
      Align: "center",
    },
    gridHeadingText: {
      fontFamily: "Baloo 2",
      fontWeight: 500,
      color: "#5BC9FE",
      fontSize: "25px",
      lineHeight: "31.02px",
    },
    gridSubHeadingText: {
      fontFamily: "Baloo 2",
      fontWeight: 400,
      color: "#909090",
      fontSize: "18px",
      lineHeight: "25.94px",
    },
    imageHeadingText: {
      fontFamily: "Baloo 2",
      fontWeight: 500,
      color: "#5BC9FE",
      fontSize: "19px",
      lineHeight: "30.44px",
      Align: "center",
      letterSpacing: "-1%",
    },
    imageSubHeadingText: {
      fontFamily: "Baloo 2",
      fontWeight: 400,
      color: "#909090",
      fontSize: "14px",
      lineHeight: "22.43px",
    },
    paymentInputLabel: {
      fontFamily: "Roboto",
      fontWeight: 400,
      color: "#030102",
      fontSize: "16px",
      lineHeight: "18.75px",
    },
    paymentInputText: {
      fontFamily: "Roboto",
      fontWeight: 400,
      color: "#30313d",
      fontSize: "14.88px",
      lineHeight: "18.75px",
    },
    paymentPlaceholder: {
      fontFamily: "Roboto",
      fontWeight: 400,
      color: "#BCC3D1",
      fontSize: "16px",
      lineHeight: "18.75px",
    },
  },

  prizes: {
    prizeCardContainer: {
      borderRadius: "16px",
      padding: "5px",
    },
    imgContainer: {
      width: "100%",
      height: "78%",
      borderRadius: "16px",
    },
    prizeCardText: {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: "700",
      lineHeight: "14.4px",
      color: "#B55434",
      width: "95%",
      textAlign: "center",
      marginTop: "10px",
      letterSpacing: "-1%",
    },
  },
  breakpoints: {
    xs: "440px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
  },
};
