import React from "react";
import { theme } from "../../theme";

const InputField = ({ label, placeholder, id, value, onChange }) => {
  return (
    <div className="flex flex-col space-y-[8px]">
      <div style={theme.typography.inputLabel}>{label}</div>
      <div>
        <input
          id={id}
          value={value}
          type="text"
          placeholder={placeholder}
          className="w-full h-14 p-4 rounded-lg border border-[#CCCCCC] focus:outline-none focus:border-2 focus:border-[#5BC9FE]"
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default InputField;
