import React from "react";
import { theme } from "../../theme";

const CButton = ({ name, onClick, width, disabled, loading }) => {
  return (
    <button
      className="inline-block rounded-lg p-[16px] text-center w-[172px]"
      style={{
        backgroundColor: disabled ? "#bdc6c7" : theme.colors.primary,
        ...theme.typography.primaryButtonText,
        width: width,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {loading ? "Submitting..." : name}
    </button>
  );
};

export default CButton;
