import React from "react";
import { theme } from "../../theme";
import celebrateIcon from "../../assets/images/celebrate.svg";
import annoucementIcon from "../../assets/images/annoucement.svg";

function MiniyanNews() {
  const NewsTab = ({ heading, desc, icon }) => {
    return (
      <div
        className="px-5 py-8 mt-4 mx-4 mb-2"
        style={{
          boxShadow: "2px 2px 27px 0px #8B8B8B40",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          width: "263px",
          height: "230px",
        }}
      >
        <div className="flex mb-4">
          <img src={icon} alt="icon" className="w-[20px] h-[20px] mr-3 mt-1" />
          <h3 style={theme.typography.gridHeadingText}>{heading}</h3>
        </div>
        <div style={theme.typography.gridSubHeadingText}>{desc}</div>
      </div>
    );
  };
  return (
    <div className="flex flex-col items-center">
      <div style={theme.typography.title}>Minyan News</div>
      <div style={theme.typography.subheading2}>
        Find out the latest news simchas and more...
      </div>
      <div
        className="flex flex-wrap justify-center overflow-y-auto mt-2 mb-2 custom-scrollbar"
        style={{ maxWidth: "900px", maxHeight: "510px" }}
      >
        <NewsTab
          heading="Mazel Tov"
          desc="we would like to wish mazal tov to our dear gabbi on the occastoin of a new born son"
          icon={celebrateIcon}
        />
        <NewsTab
          heading="Annoucement"
          desc="this week is shabbes mevorchim - tehillim will start at 9:30AM followed by shachris at 10:15AM."
          icon={annoucementIcon}
        />
        <NewsTab
          heading="Tehillim"
          desc="this week is shabbes mevorchim - tehillim will start at 9:30AM followed by shachris at 10:15AM."
          icon={annoucementIcon}
        />
        <NewsTab
          heading="Tehillim"
          desc="this week is shabbes mevorchim - tehillim will start at 9:30AM followed by shachris at 10:15AM."
          icon={annoucementIcon}
        />
        <NewsTab
          heading="Tehillim"
          desc="this week is shabbes mevorchim - tehillim will start at 9:30AM followed by shachris at 10:15AM."
          icon={annoucementIcon}
        />
        <NewsTab
          heading="Tehillim"
          desc="this week is shabbes mevorchim - tehillim will start at 9:30AM followed by shachris at 10:15AM."
          icon={annoucementIcon}
        />
        <NewsTab
          heading="Tehillim"
          desc="this week is shabbes mevorchim - tehillim will start at 9:30AM followed by shachris at 10:15AM."
          icon={annoucementIcon}
        />
        <NewsTab
          heading="Tehillim"
          desc="this week is shabbes mevorchim - tehillim will start at 9:30AM followed by shachris at 10:15AM."
          icon={annoucementIcon}
        />
      </div>
    </div>
  );
}

export default MiniyanNews;
