import React from "react";
import { theme } from "../../theme";

const SelectableButton = ({ isSelected, onClick, children }) => {
  const buttonStyle = {
    backgroundColor: isSelected ? "#5BC9FE" : "#FFFFFF",
    border: "3px solid #5BC9FE",
    width: "auto",
    minWidth: "10px",
    height: "56px",
    borderRadius: "8px",
    padding: "0 16px",
    ...theme.typography[
      isSelected ? "selectedButtonText" : "secondaryButtonText"
    ],
  };
  return (
    <button onClick={onClick} style={buttonStyle} className="text-xl md:text-3xl">
      {children}
    </button>
  );
};

export default SelectableButton;
