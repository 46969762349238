import { db } from "../db/firebase";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
  addDoc,
  updateDoc,
} from "firebase/firestore";

export const getCurrentWeek = async () => {
  try {
    const weekCollectionRef = collection(db, "common");
    const weekQuery = query(weekCollectionRef);

    const querySnapshot = await getDocs(weekQuery);

    if (querySnapshot.empty) {
      throw new Error("No week document found");
    }

    // There should be only one document
    const weekDoc = querySnapshot.docs[0];
    const weekData = weekDoc.data();

    if (!weekData.selectedWeek) {
      throw new Error("No selectedWeek field found in the week document");
    }

    return weekData.selectedWeek;
  } catch (error) {
    console.error("Error getting current week:", error);
    throw error;
  }
};

export const fetchandSetWeeksData = async (currentWeek) => {
  try {
    const weeksCollectionRef = collection(db, "weeks");
    const querySnapshot = await getDocs(weeksCollectionRef);

    const sortedWeeksData = querySnapshot.docs
      .map((doc) => doc.data())
      .sort((a, b) => b.weekId - a.weekId);
    if (!currentWeek) {
      throw new Error("Current week not found in weeks data");
    } else {
      const currentWeekId = sortedWeeksData.find(
        (week) => week.weekName === currentWeek
      ).weekId;

      const filteredWeeksData = sortedWeeksData.filter(
        (week) => week.weekId <= currentWeekId
      );
      return filteredWeeksData;
    }
  } catch (error) {
    console.error("Error fetching weeks collection: ", error);
  }
};

export const getStudentData = async (studentId) => {
  try {
    const studentDocRef = doc(db, "envelopes", studentId);
    const studentDoc = await getDoc(studentDocRef);

    if (!studentDoc.exists()) {
      throw new Error("No student document found");
    }

    return studentDoc.data();
  } catch (error) {
    console.error("Error getting student data: ", error);
    throw error;
  }
}
