import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import CButton from "../components/common/CButton";
import toast from "react-hot-toast";
import PaymentInputField from "./common/PaymentInputField";
import {
  addDoc,
  collection,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../db/firebase";
import axios from "axios";

const CheckoutForm = ({ clientSecret, onClose, selectedAmount }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [fullName, setFullName] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const amount = parseInt(selectedAmount, 10);

    if (amount < 1 || isNaN(amount)) {
      toast.error("Please select an amount to donate.");
      return;
    }

    if (!fullName) {
      toast.error("Please enter your full name.");
      return;
    }

    try {
      setIsProcessing(true);

      if (!stripe || !elements) {
        return;
      }

      toast.loading("Processing payment...");

      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/completion`,
          payment_method_data: {
            billing_details: {
              name: fullName,
              address: {
                country: "US",
                postal_code: "11213",
              },
            },
          },
          // shipping: {
          //   name: "Jenny Rosen",
          //   address: {
          //     line1: "123 Main Street",
          //     city: "San Francisco",
          //     state: "CA",
          //     country: "US",
          //     postal_code: "94321",
          //   },
          //   phone: "4152676541",
          // },
        },
        redirect: "if_required",
      });

      if (error) {
        if (error.code === "card_declined") {
          toast.dismiss();
          toast.error("Your card was declined. Please try again.");
        } else {
          toast.dismiss();
          toast.error(error.message);
        }
      } else if (paymentIntent.status === "requires_payment_method") {
        toast.dismiss();
        toast.error("Payment failed. Please try again.");
      } else if (paymentIntent.status === "requires_action") {
        toast.dismiss();
        toast.error("Insufficient funds. Please try again.");
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        const payment_method_details = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/payment/payment-method-details`,
          {
            paymentMethodId: paymentIntent.payment_method,
          }
        );

        const payload = {
          amount,
          fullName,
          paymentIntentId: paymentIntent.id,
          payment_Status: paymentIntent.status,
          payment_method_type: payment_method_details.data.data?.type,
          timeStamp: serverTimestamp(),
        };

        if (payment_method_details.data.data?.type === "card") {
          payload.payment_method_details = {
            brand: payment_method_details.data.data?.cardDetails.brand,
            last4: payment_method_details.data.data.cardDetails.last4,
            exp_month: payment_method_details.data.data.cardDetails.exp_month,
            exp_year: payment_method_details.data.data.cardDetails.exp_year,
          };
        } else if (payment_method_details.data.data?.type === "cashapp") {
          payload.payment_method_details = {
            username: payment_method_details.data.data.cashAppDetails.username,
            email: payment_method_details.data.data.cashAppDetails.email,
          };
        }

        const donationDocRef = await addDoc(
          collection(db, "donations"),
          payload
        );
        const donationId = donationDocRef.id;

        await updateDoc(donationDocRef, { donationId });

        toast.dismiss();
        toast.success("Payment Status: " + paymentIntent.status + " 💖");

        window.location.href = `${window.location.origin}/completion?donationId=${donationId}`;

        onClose();
      } else {
        toast.dismiss();
        toast.error("Something went wrong. Please try again.");
      }
    } catch (err) {
      if (err.code === "payment_intent_authentication_failure") {
        toast.dismiss();
        toast.error("Authentication failed. Please try again.");
      } else {
        toast.dismiss();
        toast.error("An error occurred. Please try again.");
      }
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div
      // id="payment-form"
      className="flex flex-col gap-6 w-full max-w-lg mx-auto p-4 sm:p-6 md:p-8 bg-white rounded-lg shadow-md"
    >
      <PaymentInputField
        label="Full name"
        placeholder="Your Name"
        id="fullName"
        value={fullName}
        onChange={(e) => setFullName(e.target.value)}
        // autoFocus={true}
      />

      <PaymentElement
        options={{
          fields: {
            billingDetails: {
              address: {
                country: "never",
              },
            },
          },
        }}
        className="w-full"
      />

      <CButton
        name={isProcessing ? "Processing..." : `Donate $${selectedAmount}`}
        onClick={handleSubmit}
        disabled={isProcessing}
      />
    </div>
  );
};

export default CheckoutForm;
