import React from "react";
import { RiFileUploadLine } from "react-icons/ri";
import { FaCheckCircle } from "react-icons/fa";

const ImageField = ({ onChange, img, imgUrl }) => {
  return (
    <div className="flex flex-col items-center border border-[#CCCCCC] rounded-lg p-4 bg-white">
      <div className="flex items-center w-full space-x-3">
        <RiFileUploadLine className="text-[#5BC9FE] mr-2" size={33} />
        <input
          type="file"
          className="hidden"
          id="fileInput"
          onChange={onChange}
          accept="image/*"
        />
        <label
          htmlFor="fileInput"
          className="text-[#5BC9FE] font-bold text-center flex-grow cursor-pointer transition-transform hover:scale-105"
          style={{
            fontFamily: "Noto Sans",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "24px",
          }}
        >
          {img ? "Uploaded" : "Profile Picture"}
        </label>
        {img && <FaCheckCircle className="text-green-500 text-2xl ml-2" />}
      </div>
      {imgUrl && (
        <div className="mt-4 relative">
          <img
            src={imgUrl}
            alt="Preview"
            className="w-32 h-32 object-cover rounded border-2 border-[#5BC9FE] shadow-md"
          />
          <button
            onClick={() => onChange({ target: { files: [] } })}
            className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 transform -translate-y-2 translate-x-2 hover:bg-red-600 transition-colors"
          >
            ✕
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageField;
