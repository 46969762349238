import React, { useEffect, useState } from "react";
import { theme } from "../theme";
import centerImage from "../assets/images/centerImage-min.png";
import envelopeIcon from "../assets/images/envelope.svg";
import minyanNewsIcon from "../assets/images/minyanNews.svg";
import aliyaIcon from "../assets/images/aliya.svg";
import pricesIcon from "../assets/images/price1.svg";
import chabadCalendarIcon from "../assets/images/chabadCalen.svg";
import rebLevikIcon from "../assets/images/rebLev.svg";
import donateIcon from "../assets/images/donation.svg";
import trophyIcon from "../assets/images/trophy.svg";
import Modal from "../components/common/Modal";
import EnvelopeContent from "../components/modalContent/EnvelopContent";
import MiniyanNewsContent from "../components/modalContent/MiniyanNews";
import { IconsDiv } from "../components/common/IconsDiv";
import RequestAliyaContent from "../components/modalContent/RequestAliyaContent";
import DonateContent from "../components/modalContent/DonateContent2";
import AboutRebLevikContent from "../components/modalContent/aboutRebLevikContent";
import LastWeekWinners from "../components/modalContent/lastWeekWinners";
import ThisWeekPrices from "../components/modalContent/ThisWeekPrices";
import { db } from "../db/firebase";
import { collection, getDocs } from "firebase/firestore";
import toast from "react-hot-toast";
import { getCurrentWeek, fetchandSetWeeksData } from "../api/thisWeekPrizeApi";
import mobileCenterImage from "../assets/images/mobileCenterImg.png";

const HomePage = () => {
  const [showEnvelopeModal, setShowEnvelopeModal] = useState(false);
  const [showMinyanNewsModal, setShowMinyanNewsModal] = useState(false);
  const [showREquestAliyaModal, setShowRequestAliyaModal] = useState(false);
  const [showDonateModal, setShowDonateModal] = useState(false);
  const [showaboutRebLevikModal, setShowAboutRebLevikModal] = useState(false);
  const [showLastWeekWinnersModal, setShowLastWeekWinnersModal] =
    useState(false);
  const [showThisWeekPricesModal, setShowThisWeekPricesModal] = useState(false);
  const [schools, setSchools] = useState([]);
  const [weeksData, setWeeksData] = useState([]);
  const [currentWeek, setCurrentWeek] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchInitialData = async () => {
    try {
      const fetchedCurrentWeek = await getCurrentWeek();
      setCurrentWeek(fetchedCurrentWeek);
      const fetchedWeeksData = await fetchandSetWeeksData(fetchedCurrentWeek);
      setWeeksData(fetchedWeeksData);
    } catch (error) {
      toast.error("Failed to fetch data");
      console.error("Error fetching initial data: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseEnvelopeModal = () => {
    setShowEnvelopeModal(false);
  };

  const handleCloseMinyanNewsModal = () => {
    setShowMinyanNewsModal(false);
  };

  const handleCloseRequestAliyaModal = () => {
    setShowRequestAliyaModal(false);
  };

  const handleCloseDonateModal = () => {
    setShowDonateModal(false);
  };

  const handleCloseAboutRebLevikModal = () => {
    setShowAboutRebLevikModal(false);
  };

  const handleCloseLastWeekWinnersModal = () => {
    setShowLastWeekWinnersModal(false);
  };

  const handleCloseThisWeekPricesModal = () => {
    setShowThisWeekPricesModal(false);
  };

  const fetchSchools = async () => {
    try {
      const schoolsCollectionRef = collection(db, "common/general/schools");
      const schoolsSnapshot = await getDocs(schoolsCollectionRef);

      const schoolList = schoolsSnapshot.docs.map((doc) => ({
        label: doc.data().name,
        value: doc.data().name,
      }));

      setSchools(schoolList);
    } catch (error) {
      toast.error("Failed to fetch schools");
    }
  };

  useEffect(() => {
    fetchSchools();
    fetchInitialData();
  }, []);

  // if (isLoading) {
  //   return (
  //     <div className="flex h-screen justify-center items-center">
  //       <Spinner/>
  //     </div>
  //   );
  // }

  return (
    <>
      <div
        className="w-full min-h-screen flex flex-col"
        style={{
          background: theme.colors.backgroundPrimary,
        }}
      >
        {/* HEADER and BODY */}
        <div
          className="w-full min-h-screen flex flex-col"
          style={{
            background: theme.colors.backgroundPrimary,
          }}
        >
          {/* Mobile Layout */}
          <div className="md:hidden flex-grow flex flex-col">
            {/* Center content */}
            <div className="w-full flex flex-col items-center justify-around pt-6">
              <div style={theme.typography.subheading} className="text-center">
                Welcome to
              </div>
              <div style={theme.typography.heading} className="text-center">
                Reb Levik's Minyan{" "}
              </div>

              <div className="w-full mt-[-25%]">
                <img
                  src={mobileCenterImage}
                  className="w-full h-full object-contain"
                  alt="centerImage"
                />
              </div>
            </div>

            {/* Icons container */}
            <div className="flex flex-row justify-around mt-6">
              {/* Left icons */}
              <div className="w-1/3 flex flex-col items-center space-y-4 px-2">
                <IconsDiv
                  onIconClick={() => setShowEnvelopeModal(true)}
                  icon={envelopeIcon}
                  text={
                    <>
                      REQUEST <br /> AN ENVELOPE
                    </>
                  }
                />
                <IconsDiv
                  icon={minyanNewsIcon}
                  onIconClick={() => setShowMinyanNewsModal(true)}
                  text={
                    <>
                      MINYAN <br /> NEWS
                    </>
                  }
                />
                <IconsDiv
                  onIconClick={() => setShowRequestAliyaModal(true)}
                  icon={aliyaIcon}
                  text={
                    <>
                      REQUEST AN <br /> ALIYA
                    </>
                  }
                />
                <IconsDiv
                  onIconClick={() => setShowThisWeekPricesModal(true)}
                  icon={pricesIcon}
                  text={
                    <>
                      THIS WEEKS <br /> PRIZE'S
                    </>
                  }
                />
              </div>
              {/* Right icons */}
              <div className="w-1/3 flex flex-col items-center justify-between space-y-4 px-2">
                <IconsDiv
                  onIconClick={() => {}}
                  icon={chabadCalendarIcon}
                  text={
                    <>
                      CHABAD <br /> CALENDAR
                    </>
                  }
                />
                <IconsDiv
                  onIconClick={() => setShowAboutRebLevikModal(true)}
                  icon={rebLevikIcon}
                  text={
                    <>
                      ABOUT <br /> REB LEVIK
                    </>
                  }
                />
                <IconsDiv
                  icon={donateIcon}
                  text={<>DONATE</>}
                  onIconClick={() =>
                    window.open(
                      "https://donate.stripe.com/7sI8xv4HX1Zx4nK9AD",
                      "_blank"
                    )
                  }
                />
                <IconsDiv
                  onIconClick={() => setShowLastWeekWinnersModal(true)}
                  icon={trophyIcon}
                  text={
                    <>
                      LAST WEEKS <br /> WINNERS
                    </>
                  }
                />
              </div>
            </div>
          </div>

          {/* Desktop Layout */}
          <div className="hidden md:flex flex-grow flex-row">
            {/* Left layout */}
            <div className="w-1/4 flex flex-col justify-between pt-[5%] pl-[5%] pr-[10%]">
              <IconsDiv
                onIconClick={() => setShowEnvelopeModal(true)}
                icon={envelopeIcon}
                text={
                  <>
                    REQUEST <br /> AN ENVELOPE
                  </>
                }
              />
              <IconsDiv
                icon={minyanNewsIcon}
                onIconClick={() => setShowMinyanNewsModal(true)}
                text={
                  <>
                    MINYAN <br /> NEWS
                  </>
                }
              />
              <IconsDiv
                onIconClick={() => setShowRequestAliyaModal(true)}
                icon={aliyaIcon}
                text={
                  <>
                    REQUEST AN <br /> ALIYA
                  </>
                }
              />
              <IconsDiv
                onIconClick={() => setShowThisWeekPricesModal(true)}
                icon={pricesIcon}
                text={
                  <>
                    THIS WEEKS <br /> PRIZE'S
                  </>
                }
              />
            </div>

            {/* Center layout */}
            <div className="w-1/2 flex flex-col items-center justify-around pt-10">
              <div style={theme.typography.heading} className="text-center">
                Reb Levik's Minyan{" "}
              </div>
              <div style={theme.typography.subheading} className="text-center">
                The largest boys Minyan
              </div>
              <div className="w-full mt-5">
                <img
                  src={centerImage}
                  className="w-full h-full object-contain"
                  alt="centerImage"
                />
              </div>
            </div>

            {/* right layout */}
            <div className="w-full md:w-1/4 flex flex-row md:flex-col justify-between pt-4 md:pt-[5%] px-4 md:pr-[10%] md:pl-[5%]">
              <IconsDiv
                onIconClick={() => {}}
                icon={chabadCalendarIcon}
                text={
                  <>
                    CHABAD <br /> CALENDAR
                  </>
                }
              />
              <IconsDiv
                onIconClick={() => setShowAboutRebLevikModal(true)}
                icon={rebLevikIcon}
                text={
                  <>
                    ABOUT <br /> REB LEVIK
                  </>
                }
              />
              <IconsDiv
                icon={donateIcon}
                text={<>DONATE</>}
                onIconClick={() =>
                  window.open(
                    "https://donate.stripe.com/7sI8xv4HX1Zx4nK9AD",
                    "_blank"
                  )
                }
              />
              <IconsDiv
                onIconClick={() => setShowLastWeekWinnersModal(true)}
                icon={trophyIcon}
                text={
                  <>
                    LAST WEEKS <br /> WINNERS
                  </>
                }
              />
            </div>
          </div>

          {/* FOOTER */}
          <div className="py-6 md:py-8 flex items-center justify-center">
            <div
              className="w-11/12 md:w-3/4 p-4 md:p-5 flex justify-center items-center rounded-[25px] md:rounded-[40px] lg:rounded-[60px]"
              style={{
                boxShadow: "1px 1px 38px -1px #00000040",
                backgroundColor: "rgba(254, 254, 254, 0.83)",
              }}
            >
              <div
                style={theme.typography.paragraph}
                className="text-xs sm:text-sm md:text-base lg:text-lg max-w-full"
              >
                Reb Levik's minyan is being established with the hope of
                instilling in our boys the respect that commanded Reb Michoel's
                tefillos 3 times each day. This minyan, to be held 10:00 each
                Shabbos morning in ....., will be under the direct leadership of
                Rabbi Karp with a support staff team of exemplary madrichim.
                Students who attend will become official members of the Reb
                Levik's minyan.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isVisible={showEnvelopeModal} onClose={handleCloseEnvelopeModal}>
        <EnvelopeContent onClose={handleCloseEnvelopeModal} schools={schools} />
      </Modal>

      <Modal
        isVisible={showMinyanNewsModal}
        onClose={handleCloseMinyanNewsModal}
      >
        <MiniyanNewsContent />
      </Modal>

      <Modal
        isVisible={showREquestAliyaModal}
        onClose={handleCloseRequestAliyaModal}
      >
        <RequestAliyaContent onClose={handleCloseRequestAliyaModal} />
      </Modal>

      <Modal isVisible={showDonateModal} onClose={handleCloseDonateModal}>
        <DonateContent onClose={handleCloseDonateModal} />
      </Modal>
      <Modal
        isVisible={showaboutRebLevikModal}
        onClose={handleCloseAboutRebLevikModal}
      >
        <AboutRebLevikContent onClose={handleCloseAboutRebLevikModal} />
      </Modal>
      <Modal
        isVisible={showLastWeekWinnersModal}
        onClose={handleCloseLastWeekWinnersModal}
      >
        <LastWeekWinners
          onClose={handleCloseLastWeekWinnersModal}
          weeksData={weeksData}
          currentWeek={currentWeek}
        />
      </Modal>
      <Modal
        isVisible={showThisWeekPricesModal}
        onClose={handleCloseThisWeekPricesModal}
      >
        <ThisWeekPrices
          onClose={handleCloseThisWeekPricesModal}
          weeksData={weeksData}
          currentWeek={currentWeek}
        />
      </Modal>
    </>
  );
};

export default HomePage;

// import React, { useEffect, useState } from "react";
// import { theme } from "../theme";
// import centerImage from "../assets/images/centerImage.png";
// import envelopeIcon from "../assets/images/envelope.svg";
// import minyanNewsIcon from "../assets/images/minyanNews.svg";
// import aliyaIcon from "../assets/images/aliya.svg";
// import pricesIcon from "../assets/images/price1.svg";
// import chabadCalendarIcon from "../assets/images/chabadCalen.svg";
// import rebLevikIcon from "../assets/images/rebLev.svg";
// import donateIcon from "../assets/images/donation.svg";
// import trophyIcon from "../assets/images/trophy.svg";
// import Modal from "../components/common/Modal";
// import EnvelopeContent from "../components/modalContent/EnvelopContent";
// import MiniyanNewsContent from "../components/modalContent/MiniyanNews";
// import { IconsDiv } from "../components/common/IconsDiv";
// import RequestAliyaContent from "../components/modalContent/RequestAliyaContent";
// import DonateContent from "../components/modalContent/DonateContent";
// import AboutRebLevikContent from "../components/modalContent/aboutRebLevikContent";
// import LastWeekWinners from "../components/modalContent/lastWeekWinners";
// import ThisWeekPrices from "../components/modalContent/ThisWeekPrices";
// import { db } from "../db/firebase";
// import { collection, getDocs } from "firebase/firestore";
// import toast from "react-hot-toast";
// import { getCurrentWeek, fetchandSetWeeksData } from "../api/thisWeekPrizeApi";

// const HomePage = () => {
//   const [showEnvelopeModal, setShowEnvelopeModal] = useState(false);
//   const [showMinyanNewsModal, setShowMinyanNewsModal] = useState(false);
//   const [showREquestAliyaModal, setShowRequestAliyaModal] = useState(false);
//   const [showDonateModal, setShowDonateModal] = useState(false);
//   const [showaboutRebLevikModal, setShowAboutRebLevikModal] = useState(false);
//   const [showLastWeekWinnersModal, setShowLastWeekWinnersModal] =
//     useState(false);
//   const [showThisWeekPricesModal, setShowThisWeekPricesModal] = useState(false);
//   const [schools, setSchools] = useState([]);
//   const [weeksData, setWeeksData] = useState([]);
//   const [currentWeek, setCurrentWeek] = useState(null);

//   useEffect(() => {
//     const fetchInitialData = async () => {
//       try {
//         const fetchedCurrentWeek = await getCurrentWeek();
//         setCurrentWeek(fetchedCurrentWeek);
//         const fetchedWeeksData = await fetchandSetWeeksData(fetchedCurrentWeek);
//         setWeeksData(fetchedWeeksData);
//       } catch (error) {
//         console.error("Error fetching initial data: ", error);
//       }
//     };

//     fetchInitialData();
//   }, []);

//   const handleCloseEnvelopeModal = () => {
//     setShowEnvelopeModal(false);
//   };

//   const handleCloseMinyanNewsModal = () => {
//     setShowMinyanNewsModal(false);
//   };

//   const handleCloseRequestAliyaModal = () => {
//     setShowRequestAliyaModal(false);
//   };

//   const handleCloseDonateModal = () => {
//     setShowDonateModal(false);
//   };

//   const handleCloseAboutRebLevikModal = () => {
//     setShowAboutRebLevikModal(false);
//   };

//   const handleCloseLastWeekWinnersModal = () => {
//     setShowLastWeekWinnersModal(false);
//   };

//   const handleCloseThisWeekPricesModal = () => {
//     setShowThisWeekPricesModal(false);
//   };

//   const fetchSchools = async () => {
//     try {
//       const schoolsCollectionRef = collection(db, "common/general/schools");
//       const schoolsSnapshot = await getDocs(schoolsCollectionRef);

//       const schoolList = schoolsSnapshot.docs.map((doc) => ({
//         label: doc.data().name,
//         value: doc.data().name,
//       }));

//       setSchools(schoolList);
//     } catch (error) {
//       toast.error("Failed to fetch schools");
//     }
//   };

//   useEffect(() => {
//     fetchSchools();
//   }, []);

//   return (
//     <>
//       <div
//         className="w-full min-h-screen flex flex-col pb-4 md:pb-6"
//         style={{
//           background: theme.colors.backgroundPrimary,
//         }}
//       >
//         {/* HEADER and BODY */}
//         <div className="flex-grow flex flex-col md:flex-row">
//           {/* left layout */}
//           <div className="w-full md:w-1/4 flex flex-row md:flex-col justify-between pt-4 md:pt-[5%] px-4 md:pl-[5%] md:pr-[10%]">
//             <IconsDiv
//               onIconClick={() => setShowEnvelopeModal(true)}
//               icon={envelopeIcon}
//               text={
//                 <>
//                   REQUEST <br /> AN ENVELOPE
//                 </>
//               }
//             />

//             <IconsDiv
//               icon={minyanNewsIcon}
//               onIconClick={() => setShowMinyanNewsModal(true)}
//               text={
//                 <>
//                   MINYAN <br /> NEWS
//                 </>
//               }
//             />
//             <IconsDiv
//               onIconClick={() => setShowRequestAliyaModal(true)}
//               icon={aliyaIcon}
//               text={
//                 <>
//                   REQUEST AN <br /> ALIYA
//                 </>
//               }
//             />
//             <IconsDiv
//               onIconClick={() => setShowThisWeekPricesModal(true)}
//               icon={pricesIcon}
//               text={
//                 <>
//                   THIS WEEKS <br />
//                   PRIZE'S
//                 </>
//               }
//             />
//           </div>

//           {/* center layout */}
//           <div className="w-full md:w-1/2 flex flex-col items-center justify-around pt-6 md:pt-10">
//             <div style={theme.typography.heading} className="text-center">
//               Reb Levik's Minyan{" "}
//             </div>
//             <div style={theme.typography.subheading} className="text-center">
//               The largest boys Minyan
//             </div>
//             <div className="w-3/4 mt-4 md:mt-5">
//               <img
//                 src={centerImage}
//                 className="w-full h-full object-contain"
//                 alt="centerImage"
//               />
//             </div>
//           </div>

//           {/* right layout */}
//           <div className="w-full md:w-1/4 flex flex-row md:flex-col justify-between pt-4 md:pt-[5%] px-4 md:pr-[10%] md:pl-[5%]">
//             <IconsDiv
//               icon={chabadCalendarIcon}
//               text={
//                 <>
//                   CHABAD <br /> CALENDAR
//                 </>
//               }
//             />
//             <IconsDiv
//               onIconClick={() => setShowAboutRebLevikModal(true)}
//               icon={rebLevikIcon}
//               text={
//                 <>
//                   ABOUT <br /> REB LEVIK
//                 </>
//               }
//             />
//             <IconsDiv
//               icon={donateIcon}
//               text={<>DONATE</>}
//               onIconClick={() => setShowDonateModal(true)}
//             />
//             <IconsDiv
//               onIconClick={() => setShowLastWeekWinnersModal(true)}
//               icon={trophyIcon}
//               text={
//                 <>
//                   LAST WEEKS <br /> WINNERS
//                 </>
//               }
//             />
//           </div>
//         </div>

//         {/* FOOTER */}
//         <div className="py-6 md:py-0 md:h-1/4 flex items-center justify-center mt-2 md:mt-3">
//           <div
//             className="w-11/12 md:w-3/4 p-4 md:p-6 flex justify-center items-center rounded-[25px] md:rounded-[40px] lg:rounded-[60px]"
//             style={{
//               boxShadow: "1px 1px 38px -1px #00000040",
//               backgroundColor: "rgba(254, 254, 254, 0.83)",
//             }}
//           >
//             <div
//               style={theme.typography.paragraph}
//               className="text-xs sm:text-sm md:text-base lg:text-lg max-w-full"
//             >
//               Reb Levik's minyan is being established with the hope of
//               instilling in our boys the respect that commanded Reb Michoel's
//               tefillos 3 times each day. This minyan, to be held 10:00 each
//               Shabbos morning in ....., will be under the direct leadership of
//               Rabbi Karp with a support staff team of exemplary madrichim.
//               Students who attend will become official members of the Reb
//               Levik's minyan.
//             </div>
//           </div>
//         </div>
//       </div>
//       <Modal isVisible={showEnvelopeModal} onClose={handleCloseEnvelopeModal}>
//         <EnvelopeContent onClose={handleCloseEnvelopeModal} schools={schools} />
//       </Modal>

//       <Modal
//         isVisible={showMinyanNewsModal}
//         onClose={handleCloseMinyanNewsModal}
//       >
//         <MiniyanNewsContent />
//       </Modal>

//       <Modal
//         isVisible={showREquestAliyaModal}
//         onClose={handleCloseRequestAliyaModal}
//       >
//         <RequestAliyaContent onClose={handleCloseRequestAliyaModal} />
//       </Modal>

//       <Modal isVisible={showDonateModal} onClose={handleCloseDonateModal}>
//         <DonateContent onClose={handleCloseDonateModal} />
//       </Modal>
//       <Modal
//         isVisible={showaboutRebLevikModal}
//         onClose={handleCloseAboutRebLevikModal}
//       >
//         <AboutRebLevikContent onClose={handleCloseAboutRebLevikModal} />
//       </Modal>
//       <Modal
//         isVisible={showLastWeekWinnersModal}
//         onClose={handleCloseLastWeekWinnersModal}
//       >
//         <LastWeekWinners onClose={handleCloseLastWeekWinnersModal} weeksData={weeksData} currentWeek={currentWeek}/>
//       </Modal>
//       <Modal
//         isVisible={showThisWeekPricesModal}
//         onClose={handleCloseThisWeekPricesModal}
//       >
//         <ThisWeekPrices onClose={handleCloseThisWeekPricesModal} weeksData={weeksData} currentWeek={currentWeek}/>
//       </Modal>
//     </>
//   );
// };

// export default HomePage;
