import React, { useState, useEffect } from "react";
import chestBox from "../../assets/images/chestBox.png";
import openedChestBox from "../../assets/images/openedChestBox.png";
import { theme } from "../../theme";
import FramedImage from "./framedImage";
import prizeFrame from "../../assets/images/framedPrize.png";
import DottedScrollbar from "./dottedScrollbar";
import noUser from "../../assets/images/noUser.png";

const AnimatedChestbox = ({
  weeksData,
  currentSlide,
  setCurrentSlide,
  selectedWeek,
  setSelectedWeek,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPrizes, setCurrentPrizes] = useState([]);

  useEffect(() => {
    setIsOpen(false);
  }, [currentSlide]);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const renderFramedPrize = ({ name, backgroundColor, img }) => {
    return (
      <div
        className="flex flex-col items-center w-full h-full hover:scale-150 transition-transform duration-300"
        style={{
          ...theme.prizes.prizeCardContainer,
          backgroundColor,
         
        }}
      >
        <div style={theme.prizes.imgContainer} className="w-full h-3/4 ">
          <img
            className="w-full h-full object-cover rounded-xl"
            src={img || noUser}
            alt="img"
          />
        </div>
        <div
          style={theme.prizes.prizeCardText}
          className="w-full h-1/4 flex items-center justify-center "
        >
          {name}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const fetchCurrentPrizes = async () => {
      const weekData = weeksData?.slice(currentSlide * 1, currentSlide * 1 + 1);
      setCurrentPrizes(weekData[0]);
    };

    fetchCurrentPrizes();
  }, [currentPrizes, weeksData, currentSlide]);

  if (!weeksData && !currentPrizes) return null;

  const hasPrizes =
    currentPrizes?.prizes &&
    currentPrizes.prizes.filter((prize) => prize.prizeImg).length > 0;

  return (
    <div className="relative w-full flex justify-center items-center">
      <div className="flex justify-center items-center xs:w-[300px] sm:w-[400px] md:w-[300px] lg:w-[520px] xl:w-[540px] h-[300px] xs:h-[340px] sm:h-[380px] md:h-[300px] lg:h-[460px] xl:h-[480px]">
        <img
          src={isOpen ? openedChestBox : chestBox}
          alt="chestbox"
          className={`w-[80%] h-[85%] transition-all duration-500 ${
            isOpen ? "scale-y-[1.1] origin-bottom" : ""
          }`}
          onClick={handleClick}
        />
      </div>

      {isOpen && (
        <div className="absolute top-0 w-[90%] md:w-[60%] md:h-[60%] h-[70%] flex justify-evenly">
          {hasPrizes ? (
            currentPrizes?.prizes
              .filter((prize) => prize.prizeImg)
              .map((prize, index) => (
                <div
                  key={prize.name}
                  className="w-[33%] h-[90%] bg-white rounded-[17px] shadow-lg m-1 flex justify-center items-center text-center pop-out-card"
                  style={{
                    animationDelay: `${index * 0.3}s`,
                    backgroundColor: "#FFAF40",
                  }}
                >
                  {renderFramedPrize({
                    name: prize.prizeName,
                    img: prize.prizeImg,
                    backgroundColor: "#FFAF40",
                  })}
                </div>
              ))
          ) : (
            <div className="w-full h-full flex items-center justify-center">
              <div
                style={{
                  ...theme.typography.subheading2,
                  textAlign: "center",
                  fontSize: "clamp(1rem, 2.5vw, 1.5rem)",
                  color: "#000000",
                  backgroundColor: "#FFAF40",
                }}
                className="pop-out-card p-5 rounded-xl"
              >
                No prizes decided yet for this week
              </div>
            </div>
          )}
        </div>
      )}

      <DottedScrollbar
        items={weeksData}
        itemsPerSlide={1}
        currentSlide={currentSlide}
        setCurrentSlide={setCurrentSlide}
        position={"absolute"}
        right={"-2%"}
        top={"40%"}
        selectedWeek={selectedWeek}
        setSelectedWeek={setSelectedWeek}
      />
    </div>
  );
};

export default AnimatedChestbox;
