import { theme } from "../../theme";
import { useState } from "react";

const pulseKeyframes = `
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(0.7); }
    100% { transform: scale(1); }
  }
`;

export const IconsDiv = ({ icon, text, onIconClick }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    onIconClick();
    setTimeout(() => setIsClicked(false), 300);
  };

  return (
    <>
      <style>{pulseKeyframes}</style>
      <div
        className="flex flex-col items-center w-[150%] cursor-pointer"
        onClick={handleClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          transform: isHovered ? "scale(1.2)" : "scale(1)",
          transition: "transform 0.2s ease-in-out",
        }}
      >
        <img
          src={icon}
          alt="icon"
          className="w-[64px] h-[64px] mb-2"
          style={{
            animation: isClicked ? "pulse 0.3s ease-in-out" : "none",
          }}
        />
        <div
          className="text-center"
          style={{
            ...theme.typography.iconLabel,
            textShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            lineHeight: "1.2em", // Adjust as needed
            maxHeight: "2.4em", // Should be 2 * lineHeight
          }}
        >
          {text}
        </div>
      </div>
    </>
  );
};
