import React from "react";
import { theme } from "../../theme";
import FramedImage from "../common/framedImage";
import frame1 from "../../assets/images/frame1.png";
import rebLevDaugther from "../../assets/images/rebLevDaughter.png";
import rebLevPortrait from "../../assets/images/rebLevPic.png";
import rebLebSon from "../../assets/images/rebLevSon.png";
import CButton from "../common/CButton";

function AboutRebLevikContent() {
  const handleBioClick = () => {
    window.open(
      "https://en.wikipedia.org/wiki/Levi_Yitzchak_Schneerson",
      "_blank"
    );
  };

  return (
    <div className="flex flex-col items-center px-4">
      <h1
        className="text-center text-[#5BC9FE]"
        style={{
          fontSize: "clamp(24px, 5vw, 46px)",
          fontFamily: "BA Hazoref",
        }}
      >
        הרה״ג והרה״ח המקובל ר׳ לוי יצחק זצ״ל
      </h1>
      <div 
        className="text-center mt-2 mb-4"
        style={{
          ...theme.typography.gridSubHeadingText,
          fontSize: "clamp(14px, 3vw, 18px)",
        }}
      >
        Read more about Reb Levik Life with the links below
      </div>
      <div className="flex md:flex-row justify-between w-full md:w-[80%] mt-6 gap-6">
        <div
          className="self-end pop-out-card"
          style={{
            animationDelay: `${0 * 0.3}s`,
          }}
        >
          <FramedImage image={rebLevDaugther} frame={frame1} width="80%" maxWidth="207px" />
        </div>
        <div
          className="self-center pop-out-card"
          style={{
            animationDelay: `${2 * 0.3}s`,
          }}
        >
          <FramedImage image={rebLevPortrait} frame={frame1} width="100%" maxWidth="260px" />
        </div>
        <div
          className="self-end pop-out-card"
          style={{
            animationDelay: `${1 * 0.3}s`,
          }}
        >
          <FramedImage image={rebLebSon} frame={frame1} width="80%" maxWidth="207px" />
        </div>
      </div>
      <div className="mt-8">
        <CButton
          name="Biography of Reb Levik"
          width="100%"
          maxWidth="229px"
          onClick={handleBioClick}
        />
      </div>
    </div>
  );
}

export default AboutRebLevikContent;

