import React, { useState, useEffect } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

function DottedScrollbar({
  items,
  itemsPerSlide,
  currentSlide,
  setCurrentSlide,
  top,
  position,
  right,
  height,
  selectedWeek,
  setSelectedWeek,
}) {
  const [totalSlides, setTotalSlides] = useState(0);
  const visibleDotsCount = 8;
  const [dotsArray, setDotsArray] = useState([]);

  const [visibleDots, setVisibleDots] = useState({
    start: 0,
    end: 0,
  });

  useEffect(() => {
    setTotalSlides(Math.ceil(items?.length / itemsPerSlide));
  }, [items, itemsPerSlide]);

  useEffect(() => {
    setVisibleDots({
      start: 0,
      end: Math.min(visibleDotsCount, totalSlides),
    });
  }, [totalSlides]);

  const handleDotClick = (index) => {
    setCurrentSlide(index);
    setSelectedWeek(items[index].weekName);
  };

  const handleScrollDown = () => {
    if (currentSlide < totalSlides - 1) {
      setSelectedWeek(items[currentSlide + 1].weekName);
      setCurrentSlide(currentSlide + 1);
      if (currentSlide >= visibleDots.end - 1) {
        setVisibleDots((prev) => ({
          start: prev.start + 1,
          end: Math.min(prev.end + 1, totalSlides),
        }));
      }
    }
  };

  const handleScrollUp = () => {
    if (currentSlide > 0) {
      setSelectedWeek(items[currentSlide - 1].weekName);
      setCurrentSlide(currentSlide - 1);
      if (currentSlide <= visibleDots.start) {
        setVisibleDots((prev) => ({
          start: Math.max(prev.start - 1, 0),
          end: prev.end - 1,
        }));
      }
    }
  };

  useEffect(() => {
    const fetchDots = () => {
      if (visibleDots.end > 0) {
        const dots = Array.from({
          length: visibleDots.end - visibleDots.start,
        }, (_, index) => index + visibleDots.start);
        setDotsArray(dots);
      }
    };
    fetchDots();
  }, [visibleDots]);

  useEffect(() => {
    // Ensure the current slide is always visible
    if (currentSlide < visibleDots.start) {
      setVisibleDots((prev) => ({
        start: currentSlide,
        end: Math.min(currentSlide + visibleDotsCount, totalSlides),
      }));
    } else if (currentSlide >= visibleDots.end) {
      setVisibleDots((prev) => ({
        start: Math.max(currentSlide - visibleDotsCount + 1, 0),
        end: currentSlide + 1,
      }));
    }
  }, [currentSlide, totalSlides]);

  if (!items || items.length === 0) return null;

  return (
    <div
      className="flex flex-col items-center transform -translate-y-1/2"
      style={{ top: top, position: position, right: right, height: height }}
    >
      <div
        className={`cursor-pointer mb-5 ${
          currentSlide === 0 ? "opacity-50" : ""
        }`}
        onClick={handleScrollUp}
      >
        <IoIosArrowUp color="#D9D9D9" size={25} />
      </div>
      {dotsArray.map((dotIndex) => (
        <div
          key={dotIndex}
          className={`w-3 h-3 mb-5 rounded-full cursor-pointer ${
            currentSlide === dotIndex
              ? "bg-blue-500"
              : "bg-gray-300"
          }`}
          onClick={() => handleDotClick(dotIndex)}
        />
      ))}
      <div
        className={`cursor-pointer mt-1 ${
          currentSlide === totalSlides - 1 ? "opacity-50" : ""
        }`}
        onClick={handleScrollDown}
      >
        <IoIosArrowDown color="#D9D9D9" size={25} />
      </div>
    </div>
  );
}

export default DottedScrollbar;