export const weeksDropdownData = [
  { label: "Bereshit", value: "Bereshit" },
  { label: "Noach", value: "Noach" },
  { label: "Lech Lecha", value: "Lech Lecha" },
  { label: "Vayera", value: "Vayera" },
  { label: "Chayei Sarah", value: "Chayei Sarah" },
  { label: "Toldos", value: "Toldos" },
  { label: "Vayeitzei", value: "Vayeitzei" },
  { label: "Vayishlach", value: "Vayishlach" },
  { label: "Vayeishev", value: "Vayeishev" },
  { label: "Miketz", value: "Miketz" },
  { label: "Vayigash", value: "Vayigash" },
  { label: "Vayechi", value: "Vayechi" },
  { label: "Shemos", value: "Shemos" },
  { label: "Vaera", value: "Vaera" },
  { label: "Bo", value: "Bo" },
  { label: "Beshalech", value: "Beshalech" },
  { label: "Yisro", value: "Yisro" },
  { label: "Mishpatim", value: "Mishpatim" },
  { label: "Terumah", value: "Terumah" },
  { label: "Tetzavah", value: "Tetzavah" },
  { label: "Ki Sisa", value: "Ki Sisa" },
  { label: "Vayakhel", value: "Vayakhel" },
  { label: "Pekudei", value: "Pekudei" },
  { label: "Vayikra", value: "Vayikra" },
  { label: "Tzav", value: "Tzav" },
  { label: "Shemini", value: "Shemini" },
  { label: "Tazria", value: "Tazria" },
  { label: "Metzorah", value: "Metzorah" },
  { label: "Acharei Mos", value: "Acharei Mos" },
  { label: "Kedoshim", value: "Kedoshim" },
  { label: "Emor", value: "Emor" },
  { label: "Behar", value: "Behar" },
  { label: "Bechukosai", value: "Bechukosai" },
  { label: "Bamidbar", value: "Bamidbar" },
  { label: "Naso", value: "Naso" },
  { label: "Behaaloscha", value: "Behaaloscha" },
  { label: "Shlach", value: "Shlach" },
  { label: "Korach", value: "Korach" },
  { label: "Chukas", value: "Chukas" },
  { label: "Balak", value: "Balak" },
  { label: "Pinchas", value: "Pinchas" },
  { label: "Matos", value: "Matos" },
  { label: "Masei", value: "Masei" },
  { label: "Devarim", value: "Devarim" },
  { label: "Vaeschanan", value: "Vaeschanan" },
  { label: "Eikev", value: "Eikev" },
  { label: "Reeh", value: "Reeh" },
  { label: "Shoftim", value: "Shoftim" },
  { label: "Ki Seizei", value: "Ki Seizei" },
  { label: "Ki Savo", value: "Ki Savo" },
  { label: "Netzavim", value: "Netzavim" },
  { label: "Vayeilech", value: "Vayeilech" },
  { label: "Haazinu", value: "Haazinu" },
  { label: "Vzos Habracha", value: "Vzos Habracha" },
];

export const HebrewMonths = [
  { label: "Elul", value: "Elul" },
  { label: "Tishre", value: "Tishre" },
  { label: "Cheshvan", value: "Cheshvan" },
  { label: "Kislev", value: "Kislev" },
  { label: "Teves", value: "Teves" },
  { label: "Shevat", value: "Shevat" },
  { label: "Adar", value: "Adar" },
  { label: "Nisan", value: "Nisan" },
  { label: "Iyar", value: "Iyar" },
  { label: "Sivan", value: "Sivan" },
  { label: "Tammuz", value: "Tammuz" },
  { label: "Av", value: "Av" },
];

export const day = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
  { label: "16", value: 16 },
  { label: "17", value: 17 },
  { label: "18", value: 18 },
  { label: "19", value: 19 },
  { label: "20", value: 20 },
  { label: "21", value: 21 },
  { label: "22", value: 22 },
  { label: "23", value: 23 },
  { label: "24", value: 24 },
  { label: "25", value: 25 },
  { label: "26", value: 26 },
  { label: "27", value: 27 },
  { label: "28", value: 28 },
  { label: "29", value: 29 },
  { label: "30", value: 30 },
  { label: "31", value: 31 },
];

export const requestAliyaType = [
  { label: "כהן", value: "Cohen" },
  { label: "לוי", value: "Levi" },
  { label: "ישראל", value: "Israel" },
];

export const gradeData = [
  { label: "1st", value: "1st" },
  { label: "2nd", value: "2nd" },
  { label: "3rd", value: "3rd" },
  { label: "4th", value: "4th" },
  { label: "5th", value: "5th" },
  { label: "6th", value: "6th" },
  { label: "7th", value: "7th" },
  { label: "8th", value: "8th" },
];
