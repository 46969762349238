import React from "react";

const DollarInputField = ({ value, onChange }) => {
  const inputContainerStyle = {
    position: "relative",
    width: "113.39px",
    height: "56px",
    borderRadius: "8px",
    border: "3px solid #5BC9FE",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
  };

  const inputStyle = {
    width: "100%",
    height: "50%",
    padding: "0 16px 0 30px",
    fontSize: "18px",
    border: "none",
    outline: "none",
    backgroundColor: "#5BC9FE",
    fontSize: "33px",
    fontWeight: "400",
    lineHeight: "40.94px",
  };

  const dollarStyle = {
    position: "absolute",
    left: "10px",
    color: "#FFFFFF",
    fontSize: "33px",
    fontWeight: "400",
    lineHeight: "40.94px",
  };

  return (
    <div style={inputContainerStyle} className="bg-[#5BC9FE]">
      <span style={dollarStyle}>$</span>
      <input
        type="text"
        value={value}
        onChange={onChange}
        style={{ ...inputStyle, paddingLeft: "30px" }}
        autoFocus
      />
    </div>
  );
};

export default DollarInputField;
