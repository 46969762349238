import React, { useEffect, useState } from "react";
import { theme } from "../../theme";
import FramedImage from "../common/framedImage";
import prizeFrame from "../../assets/images/framedPrize.png";
import DottedScrollbar from "../common/dottedScrollbar";
import { motion } from "framer-motion";
import { getStudentData } from "../../api/thisWeekPrizeApi";

function LastWeekWinners({ weeksData, currentWeek }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedWeek, setSelectedWeek] = useState(currentWeek);
  const [currentPrizes, setCurrentPrizes] = useState(null);

  useEffect(() => {
    const fetchPrizesWithStudentData = async () => {
      if (
        weeksData.length > 0 &&
        currentSlide >= 0 &&
        currentSlide < weeksData.length
      ) {
        const currentWeekPrizes = weeksData[currentSlide];
        if (currentWeekPrizes && currentWeekPrizes.prizes) {
          const prizesWithStudentData = await Promise.all(
            currentWeekPrizes.prizes
              .filter((prize) => prize.winnerImg)
              .map(async (prize, index) => {
                try {
                  const studentData = await getStudentData(prize.studentId);
                  return { ...prize, studentData };
                } catch (error) {
                  console.error(
                    `Error fetching student data for prize ${prize.id}:`,
                    error
                  );
                  return prize;
                }
              })
          );
          setCurrentPrizes({
            ...currentWeekPrizes,
            prizes: prizesWithStudentData,
          });
        }
      }
    };

    fetchPrizesWithStudentData();
  }, [weeksData, currentSlide]);

  const renderFramedPrize = (prize, index) => {
    return (
      <motion.div
        key={`${currentSlide}-${index}`}
        className="flex flex-col items-center mb-4"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: index * 0.1 }}
      >
        <FramedImage
          frame={prizeFrame}
          image={prize.winnerImg}
          width="80%"
          height="auto"
          maxWidth="80px"
        />
        <div
          style={{
            ...theme.typography.imageHeadingText,
            textAlign: "center",
            fontSize: "clamp(0.8rem, 2vw, 1rem)",
          }}
          className="mt-2"
        >
          {prize?.studentData?.firstName} {prize?.studentData?.lastName}
        </div>
        <div
          style={{
            ...theme.typography.imageSubHeadingText,
            textAlign: "center",
            fontSize: "clamp(0.7rem, 1.5vw, 0.9rem)",
          }}
        >
          {`${prize?.studentData?.Yeshiava}`}
        </div>
      </motion.div>
    );
  };

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  // if (error) {
  //   return <div>{error}</div>;
  // }

  const renderContent = () => {
    if (
      !currentPrizes ||
      !currentPrizes.prizes ||
      currentPrizes.prizes.length === 0
    ) {
      return (
        <motion.div
          className="flex items-center justify-center w-full h-40"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div
            style={{
              ...theme.typography.subheading2,
              textAlign: "center",
              fontSize: "clamp(1rem, 2.5vw, 1.5rem)",
            }}
          >
            No winners decided yet
          </div>
        </motion.div>
      );
    }

    return (
      <div className="w-[90%] flex flex-wrap justify-center">
        {currentPrizes.prizes
          .filter((prize) => prize.winnerImg)
          .map((prize, index) => renderFramedPrize(prize, index))}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center pb-10 px-2 sm:px-4 overflow-y-auto custom-scrollbar h-[80vh] md:h-full">
      <div
        style={{
          ...theme.typography.title,
          textAlign: "center",
          fontSize: "clamp(1.5rem, 4vw, 2.5rem)",
        }}
        className="mb-2"
      >
        Parshes {selectedWeek} Winners
      </div>
      <div
        style={{
          ...theme.typography.subheading2,
          textAlign: "center",
          fontSize: "clamp(0.9rem, 2.5vw, 1.2rem)",
        }}
        className="mb-5"
      >
        Scroll to View Previous weeks Prizes
      </div>
      <div className="flex items-center w-full max-w-[1200px] mt-5">
        {/* <div className="w-[90%] flex flex-wrap justify-around">
          {currentPrizes &&
            currentPrizes.prizes &&
            currentPrizes.prizes
              .filter((prize) => prize.winnerImg)
              .map((prize, index) => (
                <div key={index}>{renderFramedPrize(prize, index)}</div>
              ))}
        </div> */}
        {renderContent()}

        <DottedScrollbar
          items={weeksData}
          itemsPerSlide={1}
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
          top={
            currentPrizes &&
            currentPrizes.prizes &&
            currentPrizes.prizes.length > 4
              ? "42%"
              : "50%"
          }
          position={"absolute"}
          right={"5%"}
          selectedWeek={selectedWeek}
          setSelectedWeek={setSelectedWeek}
        />
      </div>
    </div>
  );
}

export default LastWeekWinners;
